import React from "react"
import PropTypes from "prop-types"

import "../../styles/layout.css"

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PortfolioCard from '../Portfolio/PortfolioCard'

import rumi from '../../images/dashboard.png'
import ebookstore from '../../images/ebookstore.png'
import pacman from '../../images/pacman.png'



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

export default function Portfolio(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs>
                    <PortfolioCard image={rumi} title={"Rumi"} description={"A Native Android Application"}/>
                </Grid>
                <Grid item xs>
                    <PortfolioCard image={ebookstore} title={"Ebook Store"} description={"A Java based GUI MVC Bookstore"}/>
                </Grid>
                <Grid item xs>
                    <PortfolioCard image={pacman} title={"AI Pacman"} description={"An AI Pacman Player"}/>
                </Grid>
                {/* <Grid item xs>
                    <PortfolioCard image={rumiLogin} title={"rumi"} description={"An AI Pacman Player"}/>
                </Grid> */}
            </Grid>
        </div>
        
    );
    
}

Portfolio.propTypes = {
  children: PropTypes.node.isRequired,
}